import Mixins from "../../Mixins";

export default {
  name: "AddLoan",
  mixins: [Mixins],
  data() {
    return {
      property: {
        animation: {
          addLoan: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        setting: "",
        loanCode: "",
        loanName: "",
        currencyId: this.fetchAppSession("@vue-session/config-bundle")
          .VUE_APP_CURRENCY_IDR,
        loanTypeId: "",
        akadTypeId: "",
        installmentPayId: "",
        incomeTypeId: "",
        dpdDay: "",
        loanCollectabilityCode: "",
        latechargePercentPerday: 0,
        latechargeNominalMax: 0,
        isAutodebtAccount: false,
        etapLoanMin: 0,
        marginCalculateId: "",
        provisionMin: 0,
        provisionPercent: 0,
        adminCostPercent: 0,
        adminCostMin: 0,
        bankRoleId: "",
        lateChargePayId: "",
        chartOfAccountId: "",
        marginCoaId: "",
        incomeCoaId: "",
        ppapCoaId: "",
      },
      options: {
        mataUang: [],
        kelompokPenyaluran: [],
        bentukPenyaluranDana: [],
        settingPembayaran: [],
        perananBank: [],
        jenisPendapatan: [],
        kodeKolektibilitas: [],
        metodeBayarDenda: [],
        metodePerhitunganMargin: [],
        parameterCoaList: [],
        loanCollectability: [],
      },
    };
  },
  methods: {
    async addLoan() {
      const payload = {
        loanCode: this.dataForm.loanCode,
        loanName: this.dataForm.loanName,
        currencyId: this.dataForm.currencyId,
        loanAkadTypeId: this.dataForm.loanAkadTypeId,
        akadTypeId: this.dataForm.akadTypeId,
        installmentPayId: this.dataForm.installmentPayId,
        incomeTypeId: this.dataForm.incomeTypeId,
        dpdDay: this.dataForm.dpdDay,
        loanCollectabilityCode: this.dataForm.loanCollectabilityCode,
        latechargePercentPerday: this.dataForm.latechargePercentPerday,
        latechargeNominalMax: this.dataForm.latechargeNominalMax,
        isAutodebtAccount: this.dataForm.isAutodebtAccount,
        etapLoanMin: this.dataForm.etapLoanMin,
        marginCalculateId: this.dataForm.marginCalculateId,
        provisionMin: this.dataForm.provisionMin,
        provisionPercent: this.dataForm.provisionPercent,
        adminCostPercent: this.dataForm.adminCostPercent,
        adminCostMin: this.dataForm.adminCostMin,
        productCategoryId: this.fetchAppSession("@vue-session/config-bundle")
          .VUE_APP_PRODUCT_CATEGORY_LOAN,
        lateChargePayId: this.dataForm.lateChargePayId,
        bankRoleId: this.dataForm.bankRoleId,
        loanTypeId: this.dataForm.loanTypeId,
        chartOfAccountId: this.dataForm.chartOfAccountId,
        marginCoaId: this.dataForm.marginCoaId,
        incomeCoaId: this.dataForm.incomeCoaId,
        ppapCoaId: this.dataForm.ppapCoaId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.addLoan.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "loan",
                reqUrl: "loan",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  this.$store.dispatch(
                    "SET_IDENTIFIER_LOAN_ID",
                    resp.data.data.loanId
                  );
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.addLoan.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async getReferenceCurrency() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              currencyName: "",
              page: 0,
            },
            url: "m-currency",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const text = index.currencyName;
            const value = index.currencyId;
            this.options.mataUang.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceLoanType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanTypeName;
            const value = index.loanTypeId;
            this.options.kelompokPenyaluran.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceloanAkadType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-akad-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanAkadTypeName;
            const value = index.loanAkadTypeId;
            this.options.bentukPenyaluranDana.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceLoanBankRole() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-bank-role",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanBankRoleName;
            const value = index.loanBankRoleId;
            this.options.perananBank.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceLoanIncomeType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-income-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanIncomeTypeName;
            const value = index.loanIncomeTypeId;
            this.options.jenisPendapatan.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceLoanCollectability() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collectability-code",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.loanCollectability = [
            { value: "", text: "-- Pilih --" },
          ];
          resp.data.data.map((index) => {
            const text = `${index.collectabilityCodeName}`;
            const value = index.collectabilityCodeId;
            this.options.loanCollectability.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceLoanMarginCalculate() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-margin-calculate",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanMarginCalculateName;
            const value = index.loanMarginCalculateId;
            this.options.metodePerhitunganMargin.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceLoanLateChargePay() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-late-charge-pay",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanLatechargePayName;
            const value = index.loanLatechargePayId;
            this.options.metodeBayarDenda.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceLoanInstallmentPay() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-installment-pay",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanInstallmentPayName;
            // const value = index.loanInstallmentPayId === "1";
            const value = index.loanInstallmentPayId;
            this.options.settingPembayaran.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getinstallmentPayId() {
      this.selected = this.dataForm.installmentPayId;
      if (this.selected === "1") {
        this.dataForm.marginCalculateId = "1";
      }
    },
    async helperGetParameterCoa() {
      // try {
      //   const resp = await this.$store.dispatch(
      //     "GET_REFERENCE_FROM_MASTER_PARAMETER",
      //     {
      //       url: "chart-of-account",
      //       params: {
      //         description: "",
      //         page: 0,
      //       },
      //     }
      //   );
      //   if (resp.data.code === "SUCCESS") {
      //     resp.data.data.content.map((index) => {
      //       const text = `${index.chartOfAccountCode} - ${index.description}`;
      //       const value = index.chartOfAccountId;
      //       this.options.parameterCoaList.push({ text, value });
      //     });
      //   }
      // } catch (error) {}
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "chart-of-account/list",
          endPoint: "parameter",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.accountNumber = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const text = `${index.chartOfAccountCode} - ${index.description}`;
            const value = index.chartOfAccountId;
            this.options.parameterCoaList.push({
              text,
              value,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    automationPost() {
      const toastBuefy = this.$buefy.toast.open(
        this.$NotificationUtils.success
      );
      this.$store.dispatch("SET_IDENTIFIER_LOAN_ID", "104");
    },
  },
  mounted() {
    this.getReferenceCurrency();
    this.getReferenceLoanType();
    this.getReferenceloanAkadType();
    this.getReferenceLoanBankRole();
    this.getReferenceLoanIncomeType();
    this.getReferenceLoanCollectability();
    this.getReferenceLoanMarginCalculate();
    this.getReferenceLoanLateChargePay();
    this.getReferenceLoanInstallmentPay();
    this.helperGetParameterCoa();
  },
};
